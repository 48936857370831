<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// This view contains child elements
export default {
  name: 'review-agreement',
  data() {
    return {

}
  },

}
</script>

<style scoped>




</style>